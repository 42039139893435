import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
import store from './Store';
import { createBrowserHistory } from 'history';
import Main from './Main';
import Login from './Login';
import PrivateRoute from './Components/PrivateRoute';
import Users from './Users';
import UpdateVaccines from './UpdateVaccines';
import setAuthToken from './authToken';
import jwt_decode from 'jwt-decode';
import { logoutUser } from './Store/Actions/AuthActions';
import { setCurrentUser } from './Store/Actions/AuthActions';
import ResetPassword from './ResetPassword';

export const history = createBrowserHistory();

if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    // decode and get user info
    const decoded = jwt_decode(localStorage.jwtToken);
    // check for expired token time
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // logout
        store.dispatch(logoutUser());
        // redirect to login
        window.location.href = '/Login';
    } else {
        // set user
        store.dispatch(setCurrentUser(decoded));
    }
}

function App() {
    return (
        <>
            <Provider store={store}>
                <Router history={history}>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/reset" component={ResetPassword} />
                    <PrivateRoute exact path="/users" component={Users} />
                    <PrivateRoute
                        exact
                        path="/update"
                        component={UpdateVaccines}
                    />
                    <PrivateRoute exact path="/" component={Main} />
                </Router>
            </Provider>
            <ToastContainer />
        </>
    );
}

export default App;
