import React, { useCallback } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { logoutUser as logoutAction } from '../Store/Actions/AuthActions';
import { changeLanguage as changeLanguageAction } from '../Store/Actions/UiActions';
import logo from '../logomark.png';

const PrivateRoute = ({
    component: Component,
    auth,
    ui,
    changeLanguage,
    ...rest
}) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { isChinese } = ui;
    const logout = useCallback(() => dispatch(logoutAction()), [dispatch]);
    return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated === true ? (
                    <div
                        style={{
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}
                    >
                        <nav className="navbar navbar-light bg-light">
                            <a className="navbar-brand" href="#">
                                <img
                                    src={logo}
                                    style={{
                                        marginLeft: '16px',
                                        marginRight: '8px'
                                    }}
                                    height="50"
                                    alt=""
                                />
                            </a>

                            <div class="form-inline my-2 my-lg-0">
                                {/* {pathname.includes('users') ? null : (
                                    <button
                                        onClick={() =>
                                            pathname.includes('users')
                                                ? history.replace('/')
                                                : history.push('/users')
                                        }
                                        class="btn btn-sm btn-secondary mx-3 ml-1 my-sm-0"
                                    >
                                        Manage Users
                                    </button>
                                )} */}
                                {pathname.includes('users') ||
                                pathname.includes('update') ? (
                                    <button
                                        onClick={() => history.replace('/')}
                                        class="btn btn-sm btn-secondary mx-3 ml-1 my-sm-0"
                                    >
                                        Vaccines
                                    </button>
                                ) : null}
                                {pathname.includes('update') ? null : (
                                    <button
                                        onClick={() => history.push('/update')}
                                        class="btn btn-sm btn-success mx-3 ml-1 my-sm-0"
                                    >
                                        Update Patient Vaccines
                                    </button>
                                )}
                                <button
                                    onClick={() => changeLanguage()}
                                    class="btn btn-sm btn-primary mx-1 my-sm-0"
                                >
                                    Switch to{' '}
                                    {isChinese ? 'English' : 'Chinese'}
                                </button>
                                <button
                                    onClick={() => logout()}
                                    class="btn btn-sm btn-danger mx-3 ml-1 my-sm-0"
                                >
                                    Logout
                                </button>
                            </div>
                        </nav>
                        <div style={{ flex: 1, overflow: 'auto' }}>
                            <Component {...props} />
                        </div>
                    </div>
                ) : (
                    <Redirect to="/Login" />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui
});

const mapDispatchToProps = (dispatch) => ({
    changeLanguage: () => dispatch(changeLanguageAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
