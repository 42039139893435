import * as React from 'react';

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const useFilePicker = (initialFiles = []) => {
    const [files, setFiles] = React.useState(initialFiles || []);

    const pickFile = (event) => {
        const selectedFiles = event.target.files;

        const file =
            selectedFiles?.length && selectedFiles?.length > 0
                ? selectedFiles[0]
                : null;
        if (!file) {
            return;
        }
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        });

        setFiles([...files.slice(), ...[file]]);
    };

    const removeFile = (index) => {
        setFiles(
            files.filter((_, ind) => {
                return ind !== index;
            })
        );
    };

    const state = {
        files
    };

    return {
        pickFile,
        state,
        removeFile,
        setFiles
    };
};

export default useFilePicker;
