import * as React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export const useData = (user, onDone) => {
    const [loading, setLoading] = React.useState(false);

    const createUser = (body) => {
        setLoading(true);
        axios
            .post('/auth/create', body)
            .then(({ data: { success } }) => {
                if (!success) {
                    toast.warn('User Already Exists');
                    setLoading(false);
                } else {
                    toast.success('User Created Successfully');
                    onDone();
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
                setLoading(false);
            });
    };

    const editUser = (values) => {
        setLoading(true);
        axios
            .post('/auth/edit', {
                userName: user.userName,
                updates: {
                    email: values.email,
                    name: values.name
                }
            })
            .then(({ data: { success } }) => {
                if (!success) {
                    toast.warn('User with same email already exists');
                    setLoading(false);
                } else {
                    toast.success('User Edited Successfully');
                    onDone();
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
                setLoading(false);
            });
    };

    const state = { loading };

    return {
        state,
        createUser,
        editUser
    };
};
