import * as React from 'react';
import {
    Input,
    Label,
    Button,
    Card,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import styled from 'styled-components';
import tika from '../tika.jpeg';
import mint from '../mint.png';
import useFilePicker from '../useFile';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import green from '../green.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PreviewModal from '../Components/PreviewModal';
import CreatableSelect from 'react-select/creatable';
import data from './data';
import manufacturerName from './manufacturerName';
import moment from 'moment';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { MdDeleteOutline } from 'react-icons/md';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const Container = styled.div`
    height: 100vh;
`;

const allergyList = [
    'Drug Allergy',
    'Food Allergy',
    'Insect Allergy',
    'Latex Allergy',
    'Mold Allergy',
    'Pet Allergy',
    'Pollen Allergy'
];

const medicationList = [
    'Tab Atorvatstatin 40 Mg',
    'Tab Atorvatstatin 80 Mg',
    'Tab Ecosprin 75 Mg',
    'Tab Ecosprin 150 Mg',
    'Tab Amlodipine 5 Mg',
    'Tab Amlodipine 7.5 Mg',
    'Tab Amlodipine 10 Mg',
    'Tab Telmisartan 40 Mg',
    'Tab Telmisartan 80 Mg',
    'Tab Perindopril 2 Mg',
    'Tab Perindopril 4 Mg',
    'Tab Perindopril 8 Mg',
    'Tab Losartan 25 Mg',
    'Tab Losartan 50 Mg',
    'Tab Carbamazepine 100',
    'Tab Carbamazepine 200',
    'Tab Carbamazepine 400',
    'Tab Oxcarbamazepine 150',
    'Tab Oxcarbamazepine 300',
    'Tab Oxcarbamazepine 450',
    'Tab Valproate 200',
    'Tab Valproate 500',
    'Tab Lacosamide 50',
    'Tab Lacosamide 100',
    'Tab Lacosamide 200',
    'Tab Phenytoin 100 Mg',
    'Tab Perampanel 2 Mg',
    'Tab Perampanel 4 Mg',
    'Tab Perampanel 6 Mg',
    'Tab Levetiracetam 250 Mg',
    'Tab Levetiracetam 500 Mg',
    'Tab Levetiracetam 750 Mg',
    'Tab Levetiracetam 1000 Mg',
    'Tab Brivaracetam 25 Mg',
    'Tab Brivaracetam 50 Mg',
    'Tab Brivaracetam 75 Mg',
    'Tab Brivaracetam 100 Mg',
    'Tab Clobazam 5 Mg',
    'Tab Clobazam 10 Mg',
    'Tab Topiramate 25 Mg',
    'Tab Topiramate 50 Mg',
    'Tab Topiramate 75 Mg',
    'Tab Lamotrigine 25 Mg',
    'Tab Lamotrigine 50 Mg',
    'Tab Lamotrigine 75 Mg',
    'Tab Zonisamide 100 Mg',
    'Tab Zonisamide 200 Mg',
    'Tab Gabapentine 100 Mg',
    'Tab Gabapentine 200 Mg',
    'Tab Gabapentine 300 Mg',
    'Tab Gabapentine 400 Mg',
    'Tab Pregabalin 25 Mg',
    'Tab Pregabalin 75 Mg',
    'Tab Pregabalin 150 Mg',
    'Tab Duloxetine 20 Mg',
    'Tab Duloxetine 40 Mg',
    'Tab Duloxetine 60 Mg',
    'Tab Amitriptyline 10 Mg',
    'Tab Amitriptyline 25 Mg',
    'Tab Levodopa/Carbidopa 125 Mg',
    'Tab Levodopa/Carbidopa CR 250 Mg',
    'Tab Rasagiline 0.5 Mg',
    'Tab Rasagiline 1 Mg',
    'Tab Pramipex 0.25 Mg',
    'Tab Pramipex 0.50 Mg',
    'Tab Pramipex 0.75 Mg',
    'Tab Ropinirole 2 Mg',
    'Tab Ropinirole 4 Mg',
    'Tab Safinamide 50 Mg',
    'Tab Safinamide 100 Mg',
    'Tab Entacapone 200 Mg',
    'Tab Sibellium 10 Mg',
    'Tab Melatonin 3 Mg',
    'Tab Melatonin 10 Mg',
    'Tab Clonazepam 0.25 Mg',
    'Tab Clonazepam 0.5 Mg',
    'Tab Donepezil 5 Mg',
    'Tab Memantine 5 Mg',
    'Tab Tetrabenzine 25 Mg',
    'Tab Pyridostigmine 60 Mg',
    'Tab Azathioprin 25 Mg',
    'Tab Azathioprin 50 Mg',
    'Tab Mycophenolate Mofetil 500 Mg',
    'Tab Prednisolone 5 Mg',
    'Tab Prednisolone 10 Mg',
    'Tab Prednisolone 20 Mg',
    'Tab Prednisolone 30 Mg',
    'Tab Prednisolone 40 Mg',
    'Tab Dimethyl Fumarate 100 Mg',
    'Tab Dimethyl Fumarate 200 Mg'
];

const Main = (props) => {
    const {
        state: { files },
        removeFile,
        pickFile,
        setFiles
    } = useFilePicker([]);
    const formRef = React.createRef();
    const history = useHistory();

    const [modal, setModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showPreviewModal, setShowPreviewModal] = React.useState(false);
    const [isVerified, setIsVerified] = React.useState(false);
    const [values, setValues] = React.useState({});
    const [vaccines, setVaccines] = React.useState([]);
    const [allergies, setAllergies] = React.useState([]);
    const [medications, setMedications] = React.useState([]);
    const [manufacturer, setManufacturer] = React.useState([]);
    const [email, setEmail] = React.useState('');
    const [patientName, setPatientName] = React.useState('');
    const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [lotNumber, setLotNumber] = React.useState('');
    const [verifyLoading, setVerifyLoading] = React.useState(false);
    const [isCodeGenerated, setCodeGenerated] = React.useState(false);
    const [generateLoading, setGenerateLoding] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [time, setTime] = React.useState(30);
    const [profiles, setProfiles] = React.useState([]);
    const [profilesLoading, setProfilesLoading] = React.useState(false);
    const [isNameModalVisible, setNameModalVisibility] = React.useState(null);

    const [phoneNumber, setPhoneNumber] = React.useState('');

    const getProfiles = () => {
        setProfilesLoading(true);
        axios
            .get(
                `/blockchain/profiles?email=${email}&number=${
                    phoneNumber
                        ? phoneNumber[0] == '+'
                            ? phoneNumber.slice(1)
                            : phoneNumber
                        : ''
                }`
            )
            .then((res) => {
                setProfiles(res.data.profiles.map((p) => p.fullName));
                setProfilesLoading(false);
            })
            .catch(() => {
                setProfilesLoading(false);
            });
    };

    const [vaccineArray, setVaccineArray] = React.useState([
        {
            vaccineName: '',
            manufacturer: '',
            lotNumber: '',
            administeredBy: '',
            date: moment().format('YYYY-MM-DD'),
            type: ''
        }
    ]);

    const [medicationArray, setMedicationArray] = React.useState([
        {
            name: '',
            notes: '',
            duration: '',
            quantity: ''
        }
    ]);

    const HideModal = () => {
        if (formRef.current && formRef.current.reset) {
            formRef.current.reset();
        }
        setFiles([]);
        setModal(false);
        setIsVerified(false);
        setCodeGenerated(false);
    };

    React.useEffect(() => {
        if (time <= 0 && !isCodeGenerated) {
            return;
        }
        setTimeout(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);
    }, [time, isCodeGenerated]);

    React.useEffect(() => {
        if (isVerified) getProfiles();
    }, [isVerified]);

    const onSubmitForm = async (event, values) => {
        // console.log(vaccineArray);
        // return;
        // if (vaccines.length === 0) {
        //     toast.warn('Please Add Vaccines');
        //     return;
        // }
        if (vaccineArray.length === 0) {
            toast.warn('Please Add Vaccines');
            return;
        }
        if (!patientName) {
            toast.warn('Please select Patient');
            return;
        }

        if (vaccineArray.length > 1) {
            for (let i = 0; i < vaccineArray.length; i++) {
                let tempVac = vaccineArray[i];
                if (tempVac.vaccineName.length == 0) {
                    toast.warn(
                        'Please add vaccine details or remove empty vaccines.'
                    );
                    return;
                }
            }
        }
        setValues(values);
        if (files.length === 0) {
            onSubmit();
            return;
        }
        setShowPreviewModal(true);
    };

    const onSubmit = () => {
        setShowPreviewModal(false);

        let vaccineName = '';

        vaccineArray.map((v) => {
            if (v.date && v.date.length > 0) {
                v.date = moment(v.date, 'YYYY-MM-DD').format('YYYY/MM/DD');
            }
            return v;
        });

        vaccineName = vaccineName.replace(/,(\s+)?$/, '');

        let allergiesString = '';

        allergies.forEach((v) => {
            allergiesString = allergiesString + v + ', ';
        });

        allergiesString = allergiesString.replace(/,(\s+)?$/, '');

        let medicationsString = '_';

        medications.forEach((v) => {
            medicationsString = medicationsString + v + '# ';
        });

        medicationsString = medicationsString.replace(/,(\s+)?$/, '');

        let formData = new FormData();
        const decoded = jwt_decode(localStorage.jwtToken);
        formData.append('name', patientName);
        formData.append('email', email);
        formData.append('number', phoneNumber);

        // formData.append('vaccineName', vaccineName);
        formData.append('vaccineArray', JSON.stringify(vaccineArray));
        // formData.append('medicationArray', JSON.stringify(medicationArray));
        formData.append('medications', medicationsString);
        formData.append('allergies', allergiesString);
        if (lotNumber) {
            formData.append('lotNumber', lotNumber);
        }
        // formData.append('manufacturer', manufacturer);
        formData.append(
            'date',
            moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD')
        );
        formData.append('centreOfVaccination', '');
        formData.append('createdBy', decoded.id);

        formData.append('file', files[0]);

        // console.log(files)

        // setLoading(true);

        // setTimeout(() => {
        // setLoading(false);
        // toast.success('Uploaded Successfully');
        // setModal(true);
        //     console.log('World!');
        // }, 5000);
        setLoading(true);
        setMedications([]);
        setAllergies([]);
        setVaccineArray([
            {
                vaccineName: '',
                manufacturer: '',
                lotNumber: '',
                administeredBy: '',
                date: moment().format('YYYY-MM-DD'),
                type: ''
            }
        ]);
        setFiles([]);
        axios
            .post('/blockchain/uploadMultiple', formData)
            .then((res) => {
                if (res.data.success) {
                    setLoading(false);
                    toast.success('Uploaded Successfully');
                    setModal(true);
                    return;
                }
                alert(res.data.error);
                setLoading(false);
                toast.error('Upload Failed');

                // console.log(res.data);
                // if (res.data.success) {
                //     setLoading(false);
                //     toast.success('Uploaded Successfully');
                //     setModal(true);
                // }
            })
            .catch((err) => {
                alert('Something went wrong. Please try again later.');
                setLoading(false);
                toast.error('Upload Failed');
                console.log(err);
            });
    };

    const { isChinese } = useSelector((state) => state.ui);
    const auth = useSelector((state) => state.auth);

    function validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const generateCode = (values) => {
        setEmail(values.email);
        setGenerateLoding(true);
        setTime(30);
        axios
            .post('/blockchain/emailotp', {
                email: values.email,
                number: phoneNumber
            })
            .then(() => {
                setCodeGenerated(true);
                setGenerateLoding(false);

                toast.success('Verification Code sent successfully');
            })
            .catch((err) => {
                console.log(err);

                setGenerateLoding(false);
                toast.error('Something went wrong');
            });
    };

    const verifyCode = (values) => {
        setVerifyLoading(true);
        axios
            .post('/blockchain/verifyemailotp', { email, code: values.code })
            .then(() => {
                setIsVerified(true);
                setVerifyLoading(false);
                toast.success('Verified Successfully');
            })
            .catch(() => {
                setVerifyLoading(false);
                toast.success('Incorrect code!!');
            });
    };

    const vaccineRow = (count) => {
        return (
            <Row style={{ alignItems: 'center' }}>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '疫苗種類' : `Vaccine ${count}`}
                    </Label>
                    <CreatableSelect
                        onChange={(val) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName: val.value,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy:
                                    vaccineArray[count - 1].administeredBy,
                                date: vaccineArray[count - 1].date,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                        options={data.map((d) => ({
                            value: d.name,
                            label: d.name
                        }))}
                        required
                        isClearable
                        className="mb-3"
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '生产商名称' : ` Manufacturer ${count}`}
                    </Label>
                    <CreatableSelect
                        // onChange={(val) => setManufacturer(val.value)}
                        onChange={(val) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer: val.value,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy:
                                    vaccineArray[count - 1].administeredBy,
                                date: vaccineArray[count - 1].date,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                        options={manufacturerName.map((d) => ({
                            value: d.name,
                            label: d.name
                        }))}
                        required
                        className="mb-3"
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '批号' : 'Lot No.'}
                    </Label>
                    <AvField
                        name="lotNumber"
                        placeholder={isChinese ? '输入批号' : 'Enter Lot No.'}
                        className="mb-2"
                        onChange={(event) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: event.target.value,
                                administeredBy:
                                    vaccineArray[count - 1].administeredBy,
                                date: vaccineArray[count - 1].date,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '接種日期' : 'Administered Date'}
                    </Label>
                    <AvField
                        required
                        type="date"
                        className="mb-2 form-control"
                        value={moment().format('YYYY-MM-DD')}
                        onChange={(event) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy:
                                    vaccineArray[count - 1].administeredBy,
                                date: event.target.value,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                        name="date"
                        placeholder="Administered date"
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '类型' : ` Record Type`}
                    </Label>
                    <CreatableSelect
                        // onChange={(val) => setManufacturer(val.value)}
                        onChange={(val) => {
                            console.log(vaccineArray[count - 1].vaccineName);
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy:
                                    vaccineArray[count - 1].administeredBy,
                                date: vaccineArray[count - 1].date,
                                type: val.value
                            };
                            setVaccineArray(tempArr);
                        }}
                        options={[
                            { name: 'Administered', type: 'administered' },
                            { name: 'Verified', type: 'verified' }
                        ].map((d) => ({
                            value: d.type,
                            label: d.name
                        }))}
                        className="mb-3"
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">{isChinese ? 'By' : 'By'}</Label>
                    {/* <CreatableSelect
                        clearable
                        onChange={(val) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy: val.value,
                                date: vaccineArray[count - 1].date,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                        options={[]}
                        required
                        className="mb-3"
                    /> */}
                    <AvField
                        className="mb-2 form-control"
                        name="administeredBy"
                        onChange={(event) => {
                            let tempArr = vaccineArray;
                            tempArr[count - 1] = {
                                vaccineName:
                                    vaccineArray[count - 1].vaccineName,
                                manufacturer:
                                    vaccineArray[count - 1].manufacturer,
                                lotNumber: vaccineArray[count - 1].lotNumber,
                                administeredBy: event.target.value,
                                date: vaccineArray[count - 1].date,
                                type: vaccineArray[count - 1].type
                            };
                            setVaccineArray(tempArr);
                        }}
                        placeholder=""
                    />
                </Col>

                <Col style={{ flex: 0.1 }}>
                    {count == vaccineArray.length && count > 1 && (
                        <MdDeleteOutline
                            style={{ fontSize: 28, color: 'red' }}
                            onClick={() => {
                                let newArr = vaccineArray.slice(0, -1);
                                setVaccineArray(newArr);
                            }}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    const medicationRow = (count) => {
        return (
            <Row style={{ alignItems: 'center' }}>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '姓名' : 'Medication Name'}
                    </Label>
                    <AvField
                        name="lotNumber"
                        placeholder={isChinese ? '姓名' : 'Medication Name'}
                        className="mb-2"
                        onChange={(event) => {
                            let tempArr = medicationArray;
                            tempArr[count - 1] = {
                                name: event.target.value,
                                notes: medicationArray[count - 1].notes,
                                duration: medicationArray[count - 1].duration,
                                quantity: medicationArray[count - 1].quantity
                            };
                            setVaccineArray(tempArr);
                        }}
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '笔记' : 'Notes'}
                    </Label>
                    <AvField
                        name="lotNumber"
                        placeholder={isChinese ? '笔记' : 'Notes'}
                        className="mb-2"
                        onChange={(event) => {
                            let tempArr = medicationArray;
                            tempArr[count - 1] = {
                                name: medicationArray[count - 1].name,
                                notes: event.target.value,
                                duration: medicationArray[count - 1].duration,
                                quantity: medicationArray[count - 1].quantity
                            };
                            setVaccineArray(tempArr);
                        }}
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '期间' : 'Duration'}
                    </Label>
                    <AvField
                        name="lotNumber"
                        placeholder={isChinese ? '期间' : 'Duration'}
                        className="mb-2"
                        onChange={(event) => {
                            let tempArr = medicationArray;
                            tempArr[count - 1] = {
                                name: medicationArray[count - 1].name,
                                notes: medicationArray[count - 1].notes,
                                duration: event.target.value,
                                quantity: medicationArray[count - 1].quantity
                            };
                            setVaccineArray(tempArr);
                        }}
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Label className="my-2">
                        {isChinese ? '数量' : 'Quantity'}
                    </Label>
                    <AvField
                        name="lotNumber"
                        placeholder={isChinese ? '数量' : 'Quantity'}
                        className="mb-2"
                        onChange={(event) => {
                            let tempArr = medicationArray;
                            tempArr[count - 1] = {
                                name: medicationArray[count - 1].name,
                                notes: medicationArray[count - 1].notes,
                                duration: medicationArray[count - 1].duration,
                                quantity: event.target.value
                            };
                            setVaccineArray(tempArr);
                        }}
                    />
                </Col>
                <Col style={{ flex: 0.1 }}>
                    {count == medicationArray.length && count > 1 && (
                        <MdDeleteOutline
                            style={{ fontSize: 28, color: 'red' }}
                            onClick={() => {
                                let newArr = medicationArray.slice(0, -1);
                                setMedicationArray(newArr);
                            }}
                        />
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>
            <AvForm
                ref={formRef}
                onValidSubmit={(_, val) =>
                    isVerified
                        ? onSubmitForm(_, val)
                        : isCodeGenerated
                        ? verifyCode(val)
                        : generateCode(val)
                }
            >
                <Container className="w-100" fluid>
                    <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                        <h3
                            style={{ textAlign: 'center' }}
                            className="text-align-center"
                        >
                            Welcome to TikaHealth's Blockchain Depository
                            {auth?.user?.logo && (
                                <img
                                    style={{
                                        height: '60px',
                                        objectFit: 'contain'
                                    }}
                                    src={auth?.user?.logo}
                                    className="image ml-4"
                                />
                            )}
                        </h3>

                        <div className="card card0 border-0">
                            <div className="row d-flex">
                                {/* <div className="col-lg-3">
                                    <div className="card1 pb-5">
                                        <div className="row px-3 justify-content-center mt-4 mb-5 border-line">
                                            <img src={tika} className="image" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="card2 card border-0 px-4 py-5">
                                        {/* <Label className="mb-2">
                                            1. Patient's Name
                                        </Label>

                                        <AvField
                                            className="mb-4 form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Patient's Name"
                                            required
                                        /> */}
                                        <Label className="mb-2">
                                            1.{' '}
                                            {isChinese ? '病人' : "Patient's"}{' '}
                                            Email
                                        </Label>
                                        <AvField
                                            className="my-2 form-control"
                                            // type="tel"
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                            name="email"
                                            placeholder={`${
                                                isChinese ? '病人' : "Patient's"
                                            } Email`}
                                            value={email}
                                            disabled={
                                                isVerified || isCodeGenerated
                                            }
                                        />
                                        <Label className="my-2">OR</Label>
                                        <Label className="my-2">
                                            1.{' '}
                                            {isChinese ? '病人' : "Patient's"}{' '}
                                            Phone number
                                        </Label>
                                        <PhoneInput
                                            defaultCountry="IN"
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={(val) => {
                                                setPhoneNumber(val);
                                            }}
                                            disabled={isVerified}
                                        />
                                        {isVerified && (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setCodeGenerated(false);
                                                    setIsVerified(false);
                                                }}
                                                className="ml-auto"
                                                disabled={verifyLoading}
                                            >
                                                {isChinese
                                                    ? '更改电子邮件'
                                                    : 'Change Email'}
                                            </Button>
                                        )}
                                        {!isVerified && (
                                            <>
                                                {!isCodeGenerated && (
                                                    <Row>
                                                        <Col className="ml-auto">
                                                            <Button
                                                                style={{
                                                                    float: 'right'
                                                                }}
                                                                className="ml-auto mt-3"
                                                                disabled={
                                                                    generateLoading
                                                                }
                                                            >
                                                                {generateLoading
                                                                    ? 'Loading..'
                                                                    : isChinese
                                                                    ? '生成代码'
                                                                    : 'Generate Code'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                                {isCodeGenerated && (
                                                    <>
                                                        <Label className="mt-2">
                                                            Verifcation Code
                                                        </Label>
                                                        <AvField
                                                            name="code"
                                                            placeholder="Enter Verification Code"
                                                            required
                                                        />
                                                        <p
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                            className="text-primary mt-2"
                                                            onClick={() => {
                                                                if (
                                                                    time <= 0 &&
                                                                    !verifyLoading
                                                                ) {
                                                                    generateCode(
                                                                        {
                                                                            email,
                                                                            number: phoneNumber
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Resend code{' '}
                                                            {time > 0 &&
                                                                `in ${time} seconds`}
                                                        </p>
                                                        <p className="text-success">
                                                            Verification Code
                                                            Sent to {email}
                                                        </p>
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <Button
                                                                type="button"
                                                                onClick={() => {
                                                                    setCodeGenerated(
                                                                        false
                                                                    );
                                                                    setIsVerified(
                                                                        false
                                                                    );
                                                                }}
                                                                disabled={
                                                                    verifyLoading
                                                                }
                                                            >
                                                                Change Email
                                                            </Button>
                                                            <Button
                                                                disabled={
                                                                    verifyLoading
                                                                }
                                                                color="primary"
                                                            >
                                                                {verifyLoading
                                                                    ? 'Loading..'
                                                                    : 'Verify'}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {isVerified ? (
                                            <>
                                                <Label className="my-2">
                                                    {isChinese
                                                        ? '患者姓名'
                                                        : 'Patient Name'}
                                                </Label>
                                                <CreatableSelect
                                                    isClearable
                                                    clearValue={() => {
                                                        console.log('Cleared');
                                                    }}
                                                    // clearValue={()=> {
                                                    //     console.log("CLEARED")
                                                    //     setPatientName('')}}
                                                    // setValue={(val) => {
                                                    //     console.log(val);
                                                    //     if (val.__isNew__) {
                                                    //         setNameModalVisibility(
                                                    //             val.value
                                                    //         );
                                                    //     } else {
                                                    //         console.log(val);
                                                    //         setPatientName(
                                                    //             val.value
                                                    //         );
                                                    //     }
                                                    // }}
                                                    onChange={(val) => {
                                                        console.log(val);
                                                        if (val.__isNew__) {
                                                            setNameModalVisibility(
                                                                val.value
                                                            );
                                                        } else {
                                                            console.log(val);
                                                            setPatientName(
                                                                val.value
                                                            );
                                                        }
                                                    }}
                                                    // value={{value:patientName, label:patientName}}
                                                    options={profiles.map(
                                                        (d) => ({
                                                            value: d,
                                                            label: d
                                                        })
                                                    )}
                                                    required
                                                    className="mb-3"
                                                />
                                                {vaccineArray.map(
                                                    (item, count) =>
                                                        vaccineRow(count + 1)
                                                )}
                                                <Button
                                                    outline
                                                    type="button"
                                                    onClick={() => {
                                                        setVaccineArray([
                                                            ...vaccineArray,
                                                            {
                                                                vaccineName: '',
                                                                manufacturer:
                                                                    '',
                                                                lotNumber: '',
                                                                administeredBy:
                                                                    '',
                                                                date: moment().format(
                                                                    'YYYY-MM-DD'
                                                                ),
                                                                type: ''
                                                            }
                                                        ]);
                                                    }}
                                                    style={{
                                                        width: 'auto',
                                                        alignSelf: 'center'
                                                    }}
                                                    disabled={verifyLoading}
                                                >
                                                    {isChinese
                                                        ? '更改电子邮件'
                                                        : 'Add more vaccines'}
                                                </Button>
                                                <Label className="my-2">
                                                    {isChinese
                                                        ? '过敏'
                                                        : 'Allergies'}
                                                </Label>
                                                <CreatableSelect
                                                    isMulti
                                                    onChange={(val) =>
                                                        setAllergies(
                                                            val.map(
                                                                (v) => v.value
                                                            )
                                                        )
                                                    }
                                                    options={allergyList.map(
                                                        (d) => ({
                                                            value: d,
                                                            label: d
                                                        })
                                                    )}
                                                    required
                                                    className="mb-3"
                                                />
                                                <Label className="my-2">
                                                    {isChinese
                                                        ? '药物'
                                                        : 'Medications'}
                                                </Label>
                                                <CreatableSelect
                                                    isMulti
                                                    onChange={(val) =>
                                                        setMedications(
                                                            val.map(
                                                                (v) => v.value
                                                            )
                                                        )
                                                    }
                                                    options={medicationList.map(
                                                        (d) => ({
                                                            value: d,
                                                            label: d
                                                        })
                                                    )}
                                                    required
                                                    className="mb-3"
                                                />
                                                {/* {medicationArray.map(
                                                    (item, count) =>
                                                        medicationRow(count + 1)
                                                )}
                                                <Button
                                                    outline
                                                    type="button"
                                                    onClick={() => {
                                                        setMedicationArray([
                                                            ...medicationArray,
                                                            {
                                                                name: '',
                                                                notes: '',
                                                                duration: '',
                                                                quantity: ''
                                                            }
                                                        ]);
                                                    }}
                                                    style={{
                                                        width: 'auto',
                                                        alignSelf: 'center'
                                                    }}
                                                    disabled={verifyLoading}
                                                >
                                                    {isChinese
                                                        ? '添加更多药物'
                                                        : 'Add more medications'}
                                                </Button> */}
                                                <label
                                                    class="form-label"
                                                    for="customFile"
                                                >
                                                    {isChinese
                                                        ? '疫苗接種紀錄卡'
                                                        : 'Vaccine Certificate'}
                                                </label>
                                                <input
                                                    type="file"
                                                    class="form-control"
                                                    id="customFile"
                                                    accept="image/*, application/pdf"
                                                    multiple
                                                    onChange={pickFile}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {files.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={'-file'}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                {f.type !==
                                                                    'application/pdf' && (
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={
                                                                            f.name
                                                                        }
                                                                        src={
                                                                            f.preview
                                                                        }
                                                                    />
                                                                )}
                                                            </Col>
                                                            <Col>
                                                                <p
                                                                    to="#"
                                                                    className="text-muted font-weight-bold"
                                                                >
                                                                    {f.name}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <strong>
                                                                        {
                                                                            f.formattedSize
                                                                        }
                                                                    </strong>
                                                                </p>
                                                            </Col>
                                                            {removeFile && (
                                                                <Col className="justify-content-end d-flex">
                                                                    <Button
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                i
                                                                            )
                                                                        }
                                                                        color="danger"
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                        <div className="row my-3 px-3">
                                            {isVerified ? (
                                                <Button
                                                    type="submit"
                                                    className="btn btn-secondary text-center"
                                                    disabled={loading}
                                                >
                                                    {loading
                                                        ? 'Loading...'
                                                        : isChinese
                                                        ? '送出'
                                                        : 'Submit'}
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="py-4">
                                <div className="row px-3">
                                    <small className="ml-4 ml-sm-5 mb-2">
                                        TikaHealth &copy; 2021. All rights
                                        reserved.
                                    </small>
                                    <div className="social-contact ml-4 ml-sm-auto">
                                        <span className="fa fa-facebook mr-4 text-sm"></span>
                                        <span className="fa fa-google-plus mr-4 text-sm"></span>
                                        <span className="fa fa-linkedin mr-4 text-sm"></span>
                                        <span className="fa fa-twitter mr-4 mr-sm-5 text-sm"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </AvForm>
            <Modal toggle={HideModal} isOpen={modal} centered zIndex={1400}>
                <ModalHeader>TikaHealth Authenticity</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="d-flex align-items-center mb-2">
                                <img
                                    src={green}
                                    style={{ marginRight: 12 }}
                                    height={18}
                                    width={18}
                                />
                                <p className="text-muted mb-0">
                                    {isChinese
                                        ? '檔案已被上傳'
                                        : 'Document has been submitted'}
                                </p>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <img
                                    src={green}
                                    style={{ marginRight: 12 }}
                                    height={18}
                                    width={18}
                                />
                                <p className="text-muted mb-0">
                                    {isChinese
                                        ? '檔案未曾被篡改'
                                        : 'Document has not been tampered'}
                                </p>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <img
                                    src={green}
                                    style={{ marginRight: 12 }}
                                    height={18}
                                    width={18}
                                />
                                <p className="text-muted mb-0">
                                    {isChinese
                                        ? '檔案上傳者已被認證'
                                        : "Document's issuer has been identified"}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={HideModal}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
            {showPreviewModal && (
                <PreviewModal
                    toggle={() => setShowPreviewModal(false)}
                    open={showPreviewModal}
                    image={files[0].preview}
                    // vaccines={vaccines}
                    vaccines={vaccineArray}
                    email={values.email}
                    name={values.patientName}
                    // lotNumber={values.lotNumber}
                    onContinue={onSubmit}
                    date={moment(values.date, 'YYYY-MM-DD').format(
                        'DD-MM-YYYY'
                    )}
                />
            )}
            {!!isNameModalVisible && (
                <Modal
                    isOpen={!!isNameModalVisible}
                    toggle={() => setNameModalVisibility(null)}
                >
                    <ModalBody>
                        <AvForm
                            onValidSubmit={(e, vals) => {
                                setPatientName(
                                    vals.firstName +
                                        (vals.middleName
                                            ? ' ' + vals.middleName
                                            : '') +
                                        (vals.lastName
                                            ? ' ' + vals.lastName
                                            : '')
                                );
                                setNameModalVisibility(null);
                            }}
                        >
                            <AvField
                                name="firstName"
                                placeholder="first name"
                                label="First Name"
                                value={isNameModalVisible.split(' ')[0]}
                                required
                            />
                            <AvField
                                name="middleName"
                                placeholder="middle name"
                                label="Middle Name"
                                value={isNameModalVisible.split(' ')[1]}
                            />
                            <AvField
                                name="lastName"
                                placeholder="last name"
                                label="Last Name"
                                value={isNameModalVisible
                                    .split(' ')
                                    .slice(2)
                                    .join(' ')}
                            />
                            <div className="d-flex flex-row justify-content-end">
                                <Button
                                    className="mr-2"
                                    onClick={() => setNameModalVisibility(null)}
                                >
                                    Cancel
                                </Button>
                                <Button className="success" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default Main;
