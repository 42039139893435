import * as React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row,
    Button
} from 'reactstrap';

import { useData } from './data';

const UserModal = ({ isModalVisible, toggle, user, onDone }) => {
    const formRef = React.useRef(null);

    const {
        state: { loading },
        createUser,
        editUser
    } = useData(user, () => {
        onDone();
        toggle();
    });

    return (
        <Modal className="modal-lg" isOpen={isModalVisible} toggle={toggle}>
            <ModalHeader as="h5" toggle={toggle}>
                {user ? 'Edit User' : 'Add User'}
            </ModalHeader>
            <ModalBody>
                <AvForm
                    onValidSubmit={(_, values) => {
                        if (user) {
                            editUser(values);
                        } else {
                            createUser(values);
                        }
                    }}
                    ref={formRef}
                >
                    <Row>
                        <Col xs={6}>
                            <AvField
                                value={user?.userName}
                                disabled={!!user}
                                name="userName"
                                required
                                validate={{
                                    pattern: {
                                        value: '/^[a-zA-Z0-9.-_$@*!]{2,50}$/'
                                    },
                                    required: { value: true }
                                }}
                                helpMessage="2-50 Character long (Case sensitive)"
                                label="User Name"
                                placeholder="User Name"
                            />
                        </Col>
                        <Col xs={6}>
                            <AvField
                                name="name"
                                label="Name"
                                placeholder="Name"
                                required
                                value={user?.name}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={6}>
                            <AvField
                                name="email"
                                label="Email"
                                placeholder="Email"
                                type="email"
                                required
                                value={user?.email}
                            />
                        </Col>
                        <Col xs={6}>
                            {!user && (
                                <AvField
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
                                        }
                                    }}
                                    errorMessage="Password must be 8 characters long and should be a combination of upper case and lower case letters with atleast 1 numeric and 1 special character"
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                />
                            )}
                        </Col>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggle} disabled={loading}>
                    Close
                </Button>
                <Button
                    onClick={() => formRef?.current?.submit()}
                    color="primary"
                    disabled={loading}
                >
                    {loading ? 'loading...' : user ? 'Edit' : 'Create'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserModal;
