import * as React from 'react';
import UserModal from '../UserModal';

const AddUserButton = ({ onDone, user }) => {
    const [isModalVisible, setModalVisible] = React.useState(false);

    const toggleModal = () => {
        setModalVisible((state) => !state);
    };

    return (
        <>
            <button
                onClick={toggleModal}
                className={`btn btn-sm btn-${user ? 'secondary' : 'primary'}`}
            >
                {user ? 'Edit User' : 'Add User'}
            </button>
            {isModalVisible && (
                <UserModal
                    isModalVisible={isModalVisible}
                    toggle={toggleModal}
                    onDone={onDone}
                    user={user}
                />
            )}
        </>
    );
};

export default AddUserButton;
