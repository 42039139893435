const vaccineNames = [
    {name:"DTP + Polio + Haemophilus Influenza B (5 in 1)", manufacturer:""},
    {name:"DTP + Polio + Haemophilus Influenza B + HEP B(6 in 1)", manufacturer:""},
    {name:"DTAP (Diptheria, Tetanus, Pertussis)", manufacturer:""},
    {name:"HPV 2 (Human Papillomavirus)",manufacturer:""},
    {name:"HPV 9",manufacturer:""},
    {name:"Influenza",manufacturer:""},
    {name:"CHICKEN POX",manufacturer:""},
    {name:"CHOLERA",manufacturer:""},
    {name:"DTAP + POLIO",manufacturer:""},
    {name:"HEPATITIS A (ADULT)",manufacturer:""},
    {name:"HEPATITIS A (KIDS)",manufacturer:""},
    {name:"HEPATITIS B (ADULTS & KIDS)",manufacturer:""},
    {name:"JAPANESE ENCEPHALITIS",manufacturer:""},
    {name:"MENINGOCACCAL",manufacturer:""},
    {name:"MMR (Mumps, Measles, Rubella)",manufacturer:""},
    {name:"MMR + VARICELLA",manufacturer:""},
    {name:"PNEUMOCOCCAL",manufacturer:""},
    {name:"POLIO",manufacturer:""},
    {name:"RABIES",manufacturer:""},
    {name:"ROTAVIRUS",manufacturer:""},
    {name:"HERPES ZOSTER / Shingles",manufacturer:""},
    {name:"COVID-19",manufacturer:""},
    {name:"HEP A & B",manufacturer:""},
    {name:"TETANUS",manufacturer:""},
    {name:"TYPHOID",manufacturer:""},
    {name:"YELLOW FEVER",manufacturer:""},
    // { name: 'BCG (Tuberculosis)', manufacturer: 'Daptacel' },
    // { name: 'Chicken Pox', manufacturer: 'Daptacel' },
    // { name: 'DTPA/HIB/IPV', manufacturer: 'Daptacel' },
    // { name: 'Haemophilus influenza type B', manufacturer: 'Daptacel' },
    // { name: 'Hepatitis A', manufacturer: 'Daptacel' },
    // { name: 'Hepatitis B (for children)', manufacturer: 'Daptacel' },
    // { name: 'Hepatitis B', manufacturer: 'Daptacel' },
    // { name: 'Human Papilloma virus (Cervarix)', manufacturer: 'Daptacel' },
    // { name: 'Influenza', manufacturer: 'Daptacel' },
    // { name: 'Measles, Mumps, Rubella', manufacturer: 'Daptacel' },
    // { name: 'Meningitis', manufacturer: 'Daptacel' },
    // { name: 'Meningococcal', manufacturer: 'Daptacel' },
    // { name: 'Pneumococcal 13', manufacturer: 'Daptacel' },
    // { name: 'Pneumococcal 23', manufacturer: 'Daptacel' },
    // { name: 'Rotavirus', manufacturer: 'Daptacel' },
    // { name: 'Tetanus', manufacturer: 'Daptacel' },
    // { name: 'Twinrix (Hepatitis A & B)', manufacturer: 'Daptacel' },
    // { name: 'Typhoid', manufacturer: 'Daptacel' },
];

export default vaccineNames;
