import * as React from 'react';
import {
    Table,
    Container,
    Card,
    CardBody,
    Spinner,
    Input,
    Row,
    Col,
    Label,
    Button
} from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useData } from './data';
import AddUserButton from './AddUserButton';
import { MdDeleteOutline } from 'react-icons/md';
import moment from 'moment-timezone';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';

const UpdateVaccines = () => {
    const {
        state: { users, fetchLoading, fetchError },
        getUsers
    } = useData();

    const [email, setEmail] = React.useState('');
    const [searchDone, setSearchDone] = React.useState(false);
    const [vaccines, setVaccines] = React.useState([]);
    const [medicationList, setMedicationList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const { isChinese } = useSelector((state) => state.ui);

    const [disableInput, setDisableInput] = React.useState('');

    const handleSubmit = () => {
        setIsLoading(true);
        setSearchDone(true);
        axios
            .post('/blockchain/getVaccineDataV2', { email, phoneNumber })
            .then((res) => {
                if (res.data.success) {
                    setIsLoading(false);
                    setVaccines(res.data.vaccines);
                    setMedicationList(res.data.medicationList);
                    return;
                }
                alert(res.data.error);
                setIsLoading(false);
                toast.error('Failed to fetch data');
            })
            .catch((err) => {
                alert('Something went wrong. Please try again later.');
                setIsLoading(false);
                toast.error('Failed to fetch data');
                console.log(err);
            });
    };

    const handleDelete = (vaccineId, profileId) => {
        setIsLoading(true);
        axios
            .post('/blockchain/deleteVaccine', { vaccineId, profileId, email })
            .then((res) => {
                if (res.data.success) {
                    setIsLoading(false);
                    let newVaccines = vaccines.filter(
                        (vac) => vac.vaccineId != vaccineId
                    );
                    setVaccines(newVaccines);
                    return;
                }
                alert(res.data.error);
                setIsLoading(false);
                toast.error('Failed to delete vaccine');
            })
            .catch((err) => {
                alert('Something went wrong. Please try again later.');
                setIsLoading(false);
                toast.error('Failed to delete vaccine');
                console.log(err);
            });
    };

    const getContent = () => {
        if (fetchLoading || fetchError) {
            return (
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: 300 }}
                >
                    {fetchLoading && (
                        <>
                            <h6>Loading...</h6>
                        </>
                    )}
                    {fetchError && (
                        <>
                            <h5>Something went wrong</h5>
                            <buttton
                                onClick={getUsers}
                                className="btn btn-sm btn-secondary"
                            >
                                Retry
                            </buttton>
                        </>
                    )}
                </div>
            );
        } else if (users.length == 0) {
            return (
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: 300 }}
                >
                    <h6>No User Found</h6>
                    <AddUserButton onDone={getUsers} />
                </div>
            );
        } else {
            return (
                <>
                    <Row>
                        <Col>
                            <div className="d-flex flex-row align-items-center">
                                <p
                                    style={{ marginRight: 16 }}
                                    className="mr-4 mb-0 text-secondary"
                                >
                                    Total Users -{' '}
                                    <span className="text-secondary">
                                        {users.length}
                                    </span>
                                </p>
                                <div className="ml-2" />
                                <AddUserButton onDone={getUsers} />
                                <button
                                    style={{ marginLeft: 16 }}
                                    className="btn btn-secondary btn-sm ml-3"
                                    onClick={getUsers}
                                >
                                    Refresh
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr>
                                        <td>{user.userName}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <AddUserButton
                                                onDone={getUsers}
                                                user={user}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>
            );
        }
    };

    return (
        <Container
            className="w-100"
            fluid
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}
        >
            {/* <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            > */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    width: '100%'
                }}
            >
                <div className="col-lg-12">
                    <div className="card2 card border-0 px-4 py-5">
                        <Label for="email">Patient Email</Label>
                        <Input
                            id="email"
                            name="email"
                            placeholder="Enter Patient Email"
                            onChange={(event) => {
                                if (event.target.value == '') {
                                    setDisableInput('');
                                }
                                setEmail(event.target.value);
                            }}
                            value={email}
                            type="email"
                            className="mb-4 form-control"
                            disabled={disableInput == 'email'}
                            onFocus={() => {
                                setPhoneNumber('');
                                setDisableInput('number');
                            }}
                        />
                        <Label className="my-2">OR</Label>
                        <Label className="my-2">
                            1. {isChinese ? '病人' : "Patient's"} Phone number
                        </Label>
                        <PhoneInput
                            defaultCountry="IN"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(val) => {
                                if (val == undefined) {
                                    setDisableInput('');
                                }
                                setPhoneNumber(val);
                            }}
                            onFocus={() => {
                                setEmail('');
                                setDisableInput('email');
                            }}
                            disabled={disableInput == 'number'}
                        />
                        <Button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="ml-auto"
                            // disabled={isLoading}
                        >
                            {isLoading ? 'Fetching details...' : 'Submit'}
                        </Button>
                        <Row>
                            <Col>
                                <div className="d-flex flex-row align-items-center">
                                    <p
                                        style={{ marginRight: 16 }}
                                        className="mr-4 mb-0 text-secondary"
                                    >
                                        Total Vaccines{' '}
                                        <span className="text-primary">
                                            {
                                                vaccines.filter(
                                                    (v) =>
                                                        v.vaccineName.length > 0
                                                ).length
                                            }
                                        </span>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Patient Full Name</th>
                                    <th>Vaccine Name</th>
                                    <th>Lot Number</th>
                                    <th>Manufacturer</th>
                                    <th>Administered Date</th>
                                    {/* <th>Added on</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {vaccines
                                    .filter((v) => v.vaccineName.length > 0)
                                    .map((vaccine) => {
                                        return (
                                            <tr>
                                                <td>{vaccine.fullName}</td>
                                                <td>{vaccine.vaccineName}</td>
                                                <td>{vaccine.lotNumber}</td>
                                                <td>{vaccine.manufacturer}</td>
                                                <td>{vaccine.date}</td>
                                                {/* <td>{moment(vaccine.updatedAt).format('YYYY-MM-DD, HH:MM A')}</td> */}
                                                <td>
                                                    <MdDeleteOutline
                                                        style={{
                                                            fontSize: 28,
                                                            color: 'red',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            handleDelete(
                                                                vaccine.vaccineId,
                                                                vaccine.profileId
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        {vaccines.length == 0 && searchDone && (
                            <div
                                className="d-flex flex-column align-items-center justify-content-center"
                                style={{ minHeight: 100 }}
                            >
                                <h6>No Record Found</h6>
                            </div>
                        )}
                        <Table striped hover style={{ marginTop: '32px' }}>
                            <thead>
                                <tr>
                                    <th>Patient Full Name</th>
                                    <th>Medication</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {medicationList
                                    .filter(
                                        (vaccine) =>
                                            vaccine.name &&
                                            vaccine.name != '_' &&
                                            !vaccine.name.includes('NIL')
                                    )
                                    .map((vaccine) => {
                                        return (
                                            <tr>
                                                <td>{vaccine.fullName}</td>
                                                <td>
                                                    {/* {vaccine.name} */}
                                                    {vaccine.name.charAt(0) ==
                                                    '_'
                                                        ? vaccine.name
                                                              .trim()
                                                              .replace('_', '')
                                                        : vaccine.name
                                                              .split(',')
                                                              .map((el) => {
                                                                  return (
                                                                      <div>
                                                                          {el.trim()}
                                                                      </div>
                                                                  );
                                                              })}
                                                </td>
                                                <td>
                                                    {moment(vaccine.createdAt)
                                                        .tz('Asia/Kolkata')
                                                        .format('YYYY/MM/DD')}
                                                </td>
                                                {/* <td>{moment(vaccine.updatedAt).format('YYYY-MM-DD, HH:MM A')}</td> */}
                                                {/* <td>
                                                    <MdDeleteOutline
                                                        style={{
                                                            fontSize: 28,
                                                            color: 'red',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            handleDelete(
                                                                vaccine.vaccineId,
                                                                vaccine.profileId
                                                            );
                                                        }}
                                                    />
                                                </td> */}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                {/* {getContent()} */}
            </div>
            {/* </div> */}
        </Container>
    );
};

export default UpdateVaccines;

let t = {
    success: true,
    vaccines: [
        {
            fullName: 'James Hamilton',
            vaccineName: 'YELLOW FEVER',
            allergies: '',
            lotNumber: 'W3D325V',
            manufacturer: 'STAMARIL - SANOFI',
            date: '2024/01/18',
            vaccineId: '65a88cdac3bb8506d208b5f4',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2024-01-18T02:28:42.138Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'RABIES',
            allergies: 'NIL',
            lotNumber: 'ARBA991A',
            manufacturer: 'RABIPUR - GSK',
            date: '2023/11/01',
            vaccineId: '654209aeed0b6e707d753ad1',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2023-11-01T08:17:50.479Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'DTAP + POLIO',
            allergies: 'NIL',
            lotNumber: 'W3D592V',
            manufacturer: 'ADACEL POLIO - SANOFI',
            date: '2023/11/01',
            vaccineId: '654209aded0b6e707d753ac9',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2023-11-01T08:17:49.178Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'RABIES',
            allergies: '',
            lotNumber: 'ARBA591A',
            manufacturer: 'RABIPUR - GSK',
            date: '2022/06/24',
            vaccineId: '62b541d4d7353210bc20072c',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T04:47:16.287Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'RABIES',
            allergies: '',
            lotNumber: 'ARBA591A',
            manufacturer: 'RABIPUR - GSK',
            date: '2022/06/20',
            vaccineId: '62b541d3d7353210bc200724',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T04:47:15.163Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'TYPHOID',
            allergies: '',
            lotNumber: 'U2A291V',
            manufacturer: 'TYPHIM VI - SANOFI',
            date: '2022/06/14',
            vaccineId: '62b541d5d7353210bc200734',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T04:47:17.412Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'RABIES',
            allergies: '',
            lotNumber: 'ARBA591A',
            manufacturer: 'RABIPUR - GSK',
            date: '2022/06/14',
            vaccineId: '62b541d1d7353210bc20071c',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T04:47:13.752Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'TYPHOID',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2016/03/21',
            vaccineId: '62b54d0dd7353210bc200778',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:09.530Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'HEPATITIS B (ADULTS & KIDS)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2016/03/21',
            vaccineId: '62b54d07d7353210bc200750',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:03.673Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'HEPATITIS B (ADULTS & KIDS)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2015/01/26',
            vaccineId: '62b54d06d7353210bc200748',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:02.498Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'HEPATITIS A (ADULT)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2014/01/07',
            vaccineId: '62b54d0ad7353210bc200760',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:06.018Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'TYPHOID',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2013/06/24',
            vaccineId: '62b54d0cd7353210bc200770',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:08.361Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'HEPATITIS A (ADULT)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2013/06/24',
            vaccineId: '62b54d08d7353210bc200758',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:04.836Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'DTAP (Diptheria, Tetanus, Pertussis)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '2010/01/30',
            vaccineId: '62b54d0bd7353210bc200768',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:07.200Z',
            createdBy: '627384e0ca01a17709db4d9a'
        },
        {
            fullName: 'James Hamilton',
            vaccineName: 'HEPATITIS B (ADULTS & KIDS)',
            allergies: '',
            lotNumber: '',
            manufacturer: '',
            date: '1997/06/24',
            vaccineId: '62b54d05d7353210bc200740',
            profileId: '62b1bd34c547a17c64e5222d',
            updatedAt: '2022-06-24T05:35:01.319Z',
            createdBy: '627384e0ca01a17709db4d9a'
        }
    ],
    medicationList: [
        {
            name: '_',
            createdAt: '2024-01-18T02:28:42.138Z',
            fullName: 'James Hamilton'
        },
        {
            name: '_NIL',
            createdAt: '2023-11-01T08:17:50.479Z',
            fullName: 'James Hamilton'
        },
        {
            name: '_NIL',
            createdAt: '2023-11-01T08:17:49.178Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T04:47:16.287Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T04:47:15.163Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T04:47:17.412Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T04:47:13.752Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:09.530Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:03.673Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:02.498Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:06.018Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:08.361Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:04.836Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:07.200Z',
            fullName: 'James Hamilton'
        },
        {
            createdAt: '2022-06-24T05:35:01.319Z',
            fullName: 'James Hamilton'
        }
    ]
};
