const manufacturerName = [
    { name: 'PENTAXIM - SANOFI' },
    { name: 'INFANRIX - GSK' },
    { name: 'HEXAXIM - SANOFI' },
    { name: 'INFANRIX HEXA - GSK' },
    { name: 'ADACEL - SANOFI' },
    { name: 'BOOSTRIX - GSK' },
    { name: 'CERVARIX - GSK' },
    { name: 'GARDASIL 9 - GSK' },
    { name: 'VAXIGRIP TETRA - SANOFI' },
    { name: 'FLUARIX TETRA - GSK' },
    { name: 'VARILRIX - GSK' },
    { name: 'DUKORAL - PHARMAFORTE' },
    { name: 'ADACEL POLIO - SANOFI' },
    { name: 'HAVRIX 1440 - GSK' },
    { name: 'HAVRIX 720 - GSK' },
    { name: 'ENGERIX B - GSK' },
    { name: 'IXARIO - PHARMAFORTE' },
    { name: 'IMOJEV - SANOFI' },
    { name: 'MENACTRA - SANOFI' },
    { name: 'MENVEO - GSK' },
    { name: 'PRIORIX - GSK' },
    { name: 'PROQUAD - MSD' },
    { name: 'PRIORIX TETRA - GSK' },
    { name: 'PREVENAR 13 - PFIZER' },
    { name: 'PNEUMOVAX 23 - MSD' },
    { name: 'IMOVAX - SANOFI' },
    { name: 'RABIPUR - GSK' },
    { name: 'ROTARIX - GSK' },
    { name: 'SHINGRIX - GSK' },
    { name: 'ZOSTAVAX - MSD' },
    { name: 'SINOPHARM' },
    { name: 'TWINRIX - GSK' },
    { name: 'TETAVAX - SANOFI' },
    { name: 'TYPHIM VI - SANOFI' },
    { name: 'VIVOTIF - PHARMAFORTE' },
    { name: 'STAMARIL - SANOFI' },
    { name: 'PFIZER-BIONTECH' },
    { name: 'MODERNA' },
    { name: 'JOHNSON & JOHNSON’S JANSSEN' },
    { name: 'SII' },
    { name: 'ASTRAZENECA' },
    { name: 'ASTRAZENECA' },
    { name: 'SINOVAC' },
    { name: 'BHARAT BIOTECH' },
    { name: 'COVOVAX' },
    { name: 'NUVAXOVID' }
];

export default manufacturerName;
