import * as actionTypes from '../Types';
import jwt_decode from 'jwt-decode';
import {
    uiStartLoading,
    uiStopLoading,
    setError,
    removeError,
    setPasswordSuccess,
    removePasswordSuccess
} from './UiActions';
import axios from 'axios';

import { history } from '../../App';
import setAuthToken from '../../authToken';

export const login = (token) => {
    return (dispatch) => {
        console.log(token);
        setAuthToken(token);
        localStorage.setItem('jwtToken', token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
    };
};

export const generateOtp = (mobile, resend = false) => {
    return (dispatch) => {
        dispatch(removeError());
        dispatch(uiStartLoading());
        axios
            .post('auth/generateOtp', { mobile })
            .then((res) => {
                dispatch(uiStopLoading());
                if (res.data.error) {
                    dispatch(setError(res.data.error));
                } else {
                    if (!resend) {
                        history.push({
                            pathname: '/validate',
                            state: { mobile: mobile }
                        });
                    }
                }
            })
            .catch((err) => {
                dispatch(setError('Something went wrong'));
                dispatch(uiStopLoading());
            });
    };
};

export const validateOtp = (mobile, otp) => {
    return (dispatch) => {
        dispatch(removeError());
        dispatch(uiStartLoading());
        axios
            .post('auth/validateOtp', { mobile, otp })
            .then((res) => {
                dispatch(uiStopLoading());
                if (res.data.error) {
                    dispatch(setError(res.data.error));
                } else {
                    history.push({
                        pathname: '/change',
                        state: { mobile: mobile }
                    });
                }
            })
            .catch((err) => {
                dispatch(setError('Something went wrong'));
                dispatch(uiStopLoading());
            });
    };
};

export const changePassword = (mobile, password) => {
    return (dispatch) => {
        dispatch(removeError());
        dispatch(uiStartLoading());
        axios
            .post('auth/changePassword', { mobile, password })
            .then((res) => {
                dispatch(uiStopLoading());
                if (res.data.error) {
                    dispatch(setError(res.data.error));
                } else {
                    history.push({
                        pathname: '/login'
                    });
                    dispatch(setPasswordSuccess());
                }
            })
            .catch((err) => {
                dispatch(setError('Something went wrong'));
                dispatch(uiStopLoading());
            });
    };
};

export const setCurrentUser = (token) => {
    return {
        type: actionTypes.SET_USER,
        payload: token
    };
};

export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch({
        type: actionTypes.LOGOUT
    });
};
