import React from 'react';
import { Button, Label, Alert, Spinner, Row, Col } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import logo from '../logomark.png';
import { login as loginAction } from '../Store/Actions/AuthActions';
import { changeLanguage as changeLanguageAction } from '../Store/Actions/UiActions';
import { toast } from 'react-toastify';
import axios from 'axios';

class LoginScreen extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        if (this.props.auth.isAuthenticated) this.props.history.push('/');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    onLogin = (event, values) => {
        this.setState({ loading: true });
        axios
            .post('/auth/login', {
                userName: values.userName,
                password: values.password
            })
            .then(({ data: { token, error } }) => {
                if (error || !token) {
                    toast.error('Invalid Credentials');
                    this.setState({ loading: false });
                } else {
                    this.props.login(token);
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
                this.setState({ loading: false });
            });
    };

    render() {
        const { ui } = this.props;
        return (
            <AvForm onValidSubmit={this.onLogin}>
                <Col
                    xs={12}
                    className="bg-white d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '85vh' }}
                >
                    <Alert
                        isOpen={false}
                        // toggle={this.props.removeError}
                        color="danger"
                    >
                        {''}
                    </Alert>
                    <Col className="d-flex flex-column" md={8} lg={5} xl={4}>
                        <img
                            src={logo}
                            alt="MyHealthPass"
                            style={{ height: 90, alignSelf: 'center' }}
                            className="mb-5"
                            // style={{ maxWidth: '100%' }}
                        />
                        <h3
                            className="text-center mt-2"
                            style={{ color: '#200E8F' }}
                        >
                            {ui.isChinese ? '登录开始' : 'Login to start!'}
                        </h3>
                        <AvField
                            label={ui.isChinese ? '使用者名稱' : 'User Name'}
                            name="userName"
                            placeholder={
                                ui.isChinese ? '使用者名稱' : 'Username'
                            }
                            required
                        />

                        <AvField
                            name="password"
                            label={ui.isChinese ? '密碼' : 'Password'}
                            placeholder={ui.isChinese ? '密碼' : 'password'}
                            type="password"
                            required
                        />
                        <button
                            className="btn btn-block mt-5 text-white"
                            style={{ backgroundColor: '#89E3EB' }}
                            disabled={this.state.loading}
                            type="submit"
                        >
                            {this.state.loading
                                ? 'loading...'
                                : ui.isChinese
                                ? '登入'
                                : 'Log in'}
                            {/* {ui.isLoading ? <Spinner /> : 'Log In'} */}
                        </button>
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={this.props.changeLanguage}
                            className="text-info mt-3 text-center"
                        >
                            Switch to {ui.isChinese ? 'English' : 'Mandarin'}
                        </p>
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.props.history.push('/reset')}
                            className="text-info text-center"
                        >
                            Forgot/Reset Password
                        </p>
                        <p className="text-secondary mt-3 text-center">
                            Copyright © TikaHealth Limited
                        </p>
                        <p className="mt-1 text-center text-secondary">
                            All rights reserved.
                        </p>
                    </Col>
                </Col>
                <Col
                    xs={12}
                    style={{
                        height: '15vh',
                        backgroundColor: '#00279a'
                    }}
                ></Col>
            </AvForm>
        );
    }
}

const styles = {
    inputContainer: {
        borderColor: 'black'
        //width: "100%"
    }
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui
});

const mapDispatchToProps = (dispatch) => ({
    login: (token) => dispatch(loginAction(token)),
    changeLanguage: () => dispatch(changeLanguageAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
