import React from 'react';
import { Button, Label, Alert, Spinner, Row, Col } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import logo from '../logomark.png';
import { login as loginAction } from '../Store/Actions/AuthActions';
import { changeLanguage as changeLanguageAction } from '../Store/Actions/UiActions';
import { toast } from 'react-toastify';
import axios from 'axios';

class LoginScreen extends React.Component {
    state = {
        loading: false,
        isVerified: false,
        isGenerated: false,
        email: '',
        resendLoading: false,
        time: 30
    };

    checkConfirmPassword = (_value, ctx) => {
        if (ctx.password !== ctx.confirmPassword) {
            return "Passwords don't match";
        }
        return true;
    };

    componentDidMount() {
        if (this.props.auth.isAuthenticated) this.props.history.push('/');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    resetPassword = (values) => {
        this.setState({ loading: true });
        axios
            .post('/auth/reset', {
                password: values.password,
                email: this.state.email
            })
            .then(() => {
                toast.success('Password changed successfully');
                this.props.history.push('/login');
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    resetTimer = () => {
        const timeout = setInterval(() => {
            if (this.state.time > 0) {
                this.setState((prevState) => ({
                    time: prevState.time - 1
                }));
            } else {
                clearInterval(timeout);
            }
        }, 1000);
    };

    generateOtp = (isResend) => {
        this.setState(isResend ? { resendLoading: true } : { loading: true });
        axios
            .post('/auth/otp', { email: this.state.email })
            .then(({ data: { success } }) => {
                if (success) {
                    this.setState({
                        isGenerated: true,
                        ...(isResend
                            ? { resendLoading: false }
                            : { loading: false }),
                        time: 30
                    });
                    this.resetTimer();
                    toast.success('OTP Sent..');
                } else {
                    toast.warn("user doesn't exists");
                    this.setState(
                        isResend ? { resendLoading: false } : { loading: false }
                    );
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
                this.setState(
                    isResend ? { resendLoading: false } : { loading: false }
                );
            });
    };

    verifyOtp = (values) => {
        this.setState({ loading: true });
        axios
            .post('/auth/verifyotp', {
                email: this.state.email,
                otp: values.otp
            })
            .then(({ data: { success } }) => {
                if (success) {
                    this.setState({
                        isVerified: true,
                        loading: false
                    });
                    toast.success('Verified...');
                } else {
                    toast.warn('verification failed');
                    this.setState({ loading: false });
                }
            })
            .catch(() => {
                toast.error('Something went wrong');
                this.setState({ loading: false });
            });
    };

    render() {
        const { ui } = this.props;
        return (
            <AvForm
                onValidSubmit={(_, values) =>
                    this.state.isVerified
                        ? this.resetPassword(values)
                        : this.state.isGenerated
                        ? this.verifyOtp(values)
                        : this.generateOtp()
                }
            >
                <Col
                    xs={12}
                    className="bg-white d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '85vh' }}
                >
                    <Alert
                        isOpen={false}
                        // toggle={this.props.removeError}
                        color="danger"
                    >
                        {''}
                    </Alert>
                    <Col className="d-flex flex-column" md={8} lg={5} xl={4}>
                        <img
                            src={logo}
                            alt="MyHealthPass"
                            style={{ height: 90, alignSelf: 'center' }}
                            className="mb-5 mt-3"
                            // style={{ maxWidth: '100%' }}
                        />
                        <h3
                            className="text-center mt-2"
                            style={{ color: '#200E8F' }}
                        >
                            Reset Password
                        </h3>
                        {!this.state.isVerified ? (
                            <>
                                <AvField
                                    label="Email"
                                    name="email"
                                    placeholder={'Email'}
                                    required
                                    disabled={
                                        this.state.isVerified ||
                                        this.state.isGenerated
                                    }
                                    value={this.state.email}
                                    onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                    }
                                    type="email"
                                />
                                {this.state.isGenerated && (
                                    <>
                                        <AvField
                                            label="OTP"
                                            name="otp"
                                            placeholder={'OTP'}
                                            required
                                        />
                                        <Row className="mt-4">
                                            <Col xs={6}>
                                                {' '}
                                                <p
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    className="text-primary mt-2"
                                                    onClick={() => {
                                                        if (
                                                            this.state.time <=
                                                                0 &&
                                                            !this.state.loading
                                                        ) {
                                                            this.generateOtp(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {this.state.resendLoading
                                                        ? 'sending..'
                                                        : 'Resend code ' +
                                                          `${
                                                              this.state.time >
                                                              0
                                                                  ? `in ${this.state.time} seconds`
                                                                  : ''
                                                          }`}
                                                </p>
                                            </Col>
                                            <Col xs={6}>
                                                <button
                                                    onClick={() =>
                                                        this.setState({
                                                            isGenerated: false,
                                                            isVerified: false
                                                        })
                                                    }
                                                    style={{ float: 'right' }}
                                                    className="btn btn-secondary float-right"
                                                >
                                                    Change Email
                                                </button>
                                            </Col>
                                        </Row>{' '}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {' '}
                                <AvField
                                    className="font-size-16"
                                    name="password"
                                    type="password"
                                    placeholder="Password *"
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
                                        }
                                    }}
                                    errorMessage="Password must be 8 characters long and should be a combination of upper case and lower case letters with atleast 1 numeric and 1 special character"
                                />
                                <AvField
                                    className="font-size-16 mt-3"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm Password *"
                                    validate={{
                                        required: { value: true },
                                        myValidation: this.checkConfirmPassword
                                    }}
                                    errorMessage="Passwords don't match"
                                />
                            </>
                        )}
                        <button
                            className="btn btn-block mt-5 text-white"
                            style={{ backgroundColor: '#89E3EB' }}
                            disabled={
                                this.state.loading || this.state.resendLoading
                            }
                            type="submit"
                        >
                            {this.state.loading
                                ? 'loading...'
                                : this.state.isVerified
                                ? 'Reset Password'
                                : this.state.isGenerated
                                ? 'Verify Otp'
                                : 'Generate Otp'}
                            {/* {ui.isLoading ? <Spinner /> : 'Log In'} */}
                        </button>
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={this.props.changeLanguage}
                            className="text-info mt-3 text-center"
                        >
                            Switch to {ui.isChinese ? 'English' : 'Mandarin'}
                        </p>
                        <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.props.history.push('/login')}
                            className="text-info text-center"
                        >
                            Login
                        </p>
                        <p className="text-secondary mt-3 text-center">
                            Copyright © TikaHealth Limited
                        </p>
                        <p className="mt-1 text-center text-secondary">
                            All rights reserved.
                        </p>
                    </Col>
                </Col>
                <Col
                    xs={12}
                    style={{
                        height: '15vh',
                        backgroundColor: '#00279a'
                    }}
                ></Col>
            </AvForm>
        );
    }
}

const styles = {
    inputContainer: {
        borderColor: 'black'
        //width: "100%"
    }
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui
});

const mapDispatchToProps = (dispatch) => ({
    login: (token) => dispatch(loginAction(token)),
    changeLanguage: () => dispatch(changeLanguageAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
