import * as React from 'react';
import axios from 'axios';

export const useData = () => {
    const [users, setUsers] = React.useState([]);
    const [fetchLoading, setFetchLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(false);

    const getUsers = () => {
        setFetchLoading(true);
        setFetchError(false);
        axios
            .get('/auth/list')
            .then(({ data: { users } }) => {
                setUsers(users);
                setFetchLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setFetchError(true);
                setFetchLoading(false);
            });
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    const state = {
        users,
        fetchLoading,
        fetchError
    };

    return { state, getUsers };
};
