import * as React from 'react';
import {
    Table,
    Container,
    Card,
    CardBody,
    Spinner,
    Row,
    Col
} from 'reactstrap';

import { useData } from './data';
import AddUserButton from './AddUserButton';

const Users = () => {
    const {
        state: { users, fetchLoading, fetchError },
        getUsers
    } = useData();

    console.log(fetchLoading);

    const getContent = () => {
        if (fetchLoading || fetchError) {
            return (
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: 300 }}
                >
                    {fetchLoading && (
                        <>
                            <h6>Loading...</h6>
                        </>
                    )}
                    {fetchError && (
                        <>
                            <h5>Something went wrong</h5>
                            <buttton
                                onClick={getUsers}
                                className="btn btn-sm btn-secondary"
                            >
                                Retry
                            </buttton>
                        </>
                    )}
                </div>
            );
        } else if (users.length == 0) {
            return (
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: 300 }}
                >
                    <h6>No User Found</h6>
                    <AddUserButton onDone={getUsers} />
                </div>
            );
        } else {
            return (
                <>
                    <Row>
                        <Col>
                            <div className="d-flex flex-row align-items-center">
                                <p
                                    style={{ marginRight: 16 }}
                                    className="mr-4 mb-0 text-secondary"
                                >
                                    Total Users -{' '}
                                    <span className="text-secondary">
                                        {users.length}
                                    </span>
                                </p>
                                <div className="ml-2" />
                                <AddUserButton onDone={getUsers} />
                                <button
                                    style={{ marginLeft: 16 }}
                                    className="btn btn-secondary btn-sm ml-3"
                                    onClick={getUsers}
                                >
                                    Refresh
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr>
                                        <td>{user.userName}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <AddUserButton
                                                onDone={getUsers}
                                                user={user}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>
            );
        }
    };

    return (
        <Container className="w-100" fluid>
            <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                {getContent()}
            </div>
        </Container>
    );
};

export default Users;
