import * as React from 'react';
import {
    Modal,
    ModalHeader,
    ModalFooter,
    Card,
    CardImg,
    CardBody,
    ModalBody,
    Button,
    CardText,
    CardTitle,
    CardSubtitle
} from 'reactstrap';
import { useSelector } from 'react-redux';

const PreviewModal = ({
    open,
    toggle,
    image,
    email,
    name,
    vaccines,
    onContinue,
    date
    // lotNumber
}) => {
    const { isChinese } = useSelector((state) => state.ui);

    return (
        <Modal
            className="modal-xl"
            centered
            zIndex={1200}
            toggle={toggle}
            isOpen={open}
        >
            <ModalHeader as="h4" toggle={toggle}>
                Preview
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardImg top width="30%" src={image} alt="Card image cap" />
                    <CardBody>
                        <CardTitle tag="p">
                            Please Review the provided Information
                        </CardTitle>
                        <p className="mb-1">
                            <span style={{ fontWeight: 'bold' }}>
                                {isChinese ? '病人' : "Patient's"} Email
                            </span>{' '}
                            - {email}
                        </p>
                        <p className="mb-1">
                            <span style={{ fontWeight: 'bold' }}>
                                {isChinese ? '病人' : "Patient's"} Name
                            </span>
                            - {name}
                        </p>

                        {/* <p className="mb-1">
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {isChinese ? '批号' : 'Lot No.'}
                            </span>{' '}
                            - {lotNumber || '-'}
                        </p> */}
                        <p className="mb-1">
                            <span style={{ fontWeight: 'bold' }}>
                                Vaccines.
                            </span>{' '}
                            - {vaccines.map((item) => item.vaccineName) || '-'}
                        </p>
                        <p className="mb-1">
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {isChinese ? '接種日期' : 'Administered Date'}
                            </span>{' '}
                            - {date}
                        </p>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} color="danger">
                    Cancel
                </Button>
                <Button onClick={onContinue} color="primary" className="ml-2">
                    Continue
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PreviewModal;
